import React from "react"
import PropTypes from "prop-types"

// Components
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Tag from "../components/tag"

const TagsPage = ({
	location,
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title, subtitle },
    },
		allPosts: { group: devGroup },
  },
}) => {
	let allTags = group;
	devGroup.forEach((tag) => {
		const foundTag = allTags.find(e => e.fieldValue === tag.fieldValue);
		if (foundTag) {
			foundTag.totalCount += tag.totalCount;
		} else {
			allTags.push(tag);
		}
	})
	const el = (
		<Layout location={location} title={title} subtitle={subtitle}>
	    <Helmet title={title} />
	    <div>
	      <h1>Tags</h1>
	      <ul className="tag-list">
	        {allTags.map(tag => (
	          <li key={tag.fieldValue}>
	            <Tag name={tag.fieldValue} />
							{
								// <span>({tag.totalCount})</span>
							}
	          </li>
	        ))}
	      </ul>
	    </div>
	  </Layout>
	)
	allTags = null
	return el;
};

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
				subtitle
      }
    }
    allMarkdownRemark(filter: { frontmatter: { published: { eq: true } } }, limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    allPosts(limit: 2000) {
      group(field: tag_list) {
        fieldValue
        totalCount
      }
    }
  }
`

import React from "react"

import { Link } from "gatsby";

import "devicon/devicon.min.css";

const tagNames = {
	cplusplus: "C++",
	csharp: "C#",
	css: "CSS",
	docs: "Docs",
	gamedev: "Game Dev",
	gatsby: "Gatsby",
	html: "HTML",
	javascript: "JavaScript",
	n64: "N64",
	node: "Node.js",
	python: "Python",
	rails: "Rails",
	webdev: "Web Dev",
	react: "React",
	tutorials: "Tutorials",
	ruby: "Ruby",
	api: "API",
}

const Tag = (props) => {
    const { name } = props
		let deviconLabel = name;
		// Change DEV tags to Devicon icon names if they don't match
		if (name === "node") deviconLabel = "nodejs"

    return (
        <span className="tech-tag">
            <Link to={`/tags/${name}/`}>
                {tagNames[name]}
								<i className={`devicon-${deviconLabel}-plain colored`}></i>
            </Link>
        </span>
    )
}

export default Tag
